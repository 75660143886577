html {
  background-color: #303030;
}

body {
  overflow: hidden;
}

.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: #383838;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 9999s ease-in-out 0s;
  transition: background-color 9999s ease-in-out 0s;
}

/* .mce-content-body > h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0 !important;
  margin: 0 !important;
  line-height: 1.2 !important;
} */

.mce-content-body h1,
.mce-content-body h2,
.mce-content-body h3,
.mce-content-body h4,
.mce-content-body h5,
.mce-content-body h6,
.mce-content-body p {
  padding: 0;
  margin: 0;
  line-height: 1.2;
}

/* To fix centering issue with pintura */
.PinturaRoot[data-env~='landscape'][data-env~='is-compact'][data-env~='has-navigation'] {
  grid-template-columns: 8em auto 8em !important;
}

.PinturaStatusAside {
  transform: translate(35px, 50px) scale(2.5) !important;
}

.tox-tinymce-inline {
  z-index: 9999;
  outline: 0px solid #dbdbdb;
  margin-top: -10px;
  width: auto !important;
}
.tox-tinymce-aux.tox-tinymce-aux {
  z-index: 99999;
}

.tox .tox-tbtn--bespoke .tox-tbtn__select-label.tox-tbtn__select-label {
  width: 3em;
}

.tox-editor-header.tox-editor-header {
  border-radius: 4px;
}

.tox-number-input.tox-number-input {
  display: flex;
}
.tox-input-wrapper.tox-input-wrapper {
  width: 80px;
}

.tox-toolbar__primary.tox-toolbar__primary {
  width: auto;
}
.tox-toolbar-overlord.tox-toolbar-overlord {
  width: auto;
}

.tox-input-wrapper.tox-input-wrapper input {
  text-align: center;
  width: 100%;
}

.tox .tox-menubar + .tox-toolbar-overlord .tox-toolbar__primary.tox-toolbar__primary {
  border-color: #afadad;
}

.tox:not([dir='rtl']) .tox-toolbar__group.tox-toolbar__group:not(:last-of-type) {
  border-color: #afadad;
}

.tox-textarea-wrap.tox-textarea-wrap {
  flex: 1 1 auto;
}
.tox-textarea-wrap.tox-textarea-wrap textarea {
  height: 100%;
}

.tox-dialog-wrap__backdrop {
  background-color: rgb(0 0 0 / 75%) !important;
}
